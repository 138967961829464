<template>
  <div>
    <!-- <ProductArticle></ProductArticle>
    <ProductReleted></ProductReleted> -->
    <div v-if="loading" class="text-center">
      <img  :src="'/loading.gif'" style="width: 100px; height:75px;" alt="logo">
    </div>

    <div v-else>
      <ProductDetailsSection></ProductDetailsSection>
      <ProductArticle></ProductArticle>
      <ProductReleted></ProductReleted>
    </div>
    <!-- {{productDetails}} -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductArticle from "@/components/frontend/productDetails/ProductArticle.vue";
import ProductReleted from "@/components/frontend/productDetails/ProductReleted.vue";
import ProductDetailsSection from "@/components/frontend/productDetails/ProductDetailsSection.vue";
export default {
  name: "Details",
  components: {
    ProductArticle,
    ProductReleted,
    ProductDetailsSection,
  },
  watch: {
    $route (){
      const slug = this.$route.params.slug;
      const type = this.$route.params.type;
      this.$store.dispatch("getProductDetails", slug + "/" + type);
    }
  },
  computed: {
    ...mapGetters(["productDetails","loading"]),
  },
  mounted() {
    const slug = this.$route.params.slug;
    const type = this.$route.params.type;
    this.$store.dispatch("getProductDetails", slug + "/" + type);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/frontend/style/details.scss";
</style>
