<template>
  <div class="product_content">
    <!--<pre>{{productDetails.groupProducts}}</pre>-->
    <!--country_of_manufacture_id-->
    <h1
                  style="
                    font-size: 18px;
                    font-weight: bolder;
                    color: #6f6f6f;
                    text-align: start;
                    margin-bottom: 5px;
                  "
                >
                  {{ productDetails.name }}
                </h1>
    
      <!-- <b>{{ productName }}</b> -->
      <p>By
        <router-link :to="{ name: 'brand', params: { slug: slug } }">
          {{ brandName ? brandName : productDetails.groupProducts[0].product.brand.name }}
        </router-link>
      </p>
      
    <p v-if="origin">
      Manufacturer Country: <strong>{{ origin }}</strong>
    </p>
    <!--    <pre>{{productDetails}}</pre>-->
    <hr v-if="productDetails.groupProducts[0].product.description">
    <article v-if="productDetails.groupProducts[0].product.description">
      <div class="short-desc" v-html="productDetails.groupProducts[0].product.description"></div>
    </article>
    <!--<p>
            &lt;!&ndash;{{ description }}&ndash;&gt;
            {{productDetails.groupProducts[0].product.description}}
        </p>-->
    <p v-if="sku">
      SKU - {{ sku }}
    </p>

    <h3 v-if="showPriceRange"><span style="font-size: 17px; font-weight: 500">Price :</span> {{
      productDetails.min_price }} TK - {{ productDetails.max_price }} TK</h3>
    <h3 v-if="!showPriceRange"><span v-if="singleProct.product_price[0].offer_price > 0"><span
          style="font-size: 17px; font-weight: 500">Price :</span> {{ singleProct.product_price[0].offer_price }}</span>
      <span v-else><span style="font-size: 17px; font-weight: 500">Price :</span>
        {{ singleProct.product_price[0].price }}</span>
      TK
    </h3>

    <!-- <h4 v-if="productDetails.flat_rate_value > 0">
          Deliver Charge : {{ price }} TK
        </h4> -->
    <!--<pre>{{productDetails.groupProducts[0].product.product_size}}</pre>-->
    <h6 v-if="productDetails.groupProducts.find(x => x.product.product_color !== null)">Colors</h6>
    <ul v-if="productDetails.groupProducts.find(x => x.product.product_color !== null)" class="choice_color">
      <template>
        <v-select :options="productDetails.groupProducts" v-model="img" @input="productByColor(img, img.id)"
          class="color-dropdown" placeholder="Select Color">
          <template v-if="ac.product.product_color !== null" slot="option" slot-scope="ac"
            style="width: 250px; min-height: 25px; ">
            <img v-if="ac.product.product_color !== null" style="height: 25px; width: 50px; border-radius: 3px"
              :src="'https://savershall.com/suadmin/' + ac.product.product_color.color_image" alt="" />
            - {{ ac.product.product_color.name }}
          </template>
          <template v-if="ac.product.product_color !== null" slot="selected-option" slot-scope="ac">
            <div style=" width: 250px; min-height: 25px; display: flex">
              <img v-if="ac.product.product_color !== null" style="border-radius: 3px; height:25px; width:50px"
                :src="'https://savershall.com/suadmin/' + ac.product.product_color.color_image" alt="" />
              - {{ ac.product.product_color.name }}
            </div>
          </template>
        </v-select>

      </template>
      <!--<li v-for="pc in productDetails.groupProducts" :key="pc.id">
            <img v-if="pc.product.product_color !== null" class="img-rounded"
                 :class="{'selectedImage' :selectedClrId === pc.id} "
                 :src="'http://43.204.54.58/eurasia-admin/'+pc.product.product_color.color_image" alt=""
                 @click="productByColor(pc, pc.id)"/>
            &lt;!&ndash; <label>&nbsp; {{ pc.product_id }} - {{ pc.name }}</label> &ndash;&gt;
        </li>-->
    </ul>
    <div v-if="productDetails.groupProducts.find(x => x.product.product_size !== null)">
      <h6 v-if="productDetails.groupProducts.find(x => x.product.product_size !== null)"><span>Size</span></h6>
      <ul v-if="productDetails.product_type === 2" class="size_box">
        <li v-for="pz in productDetails.groupProducts" :key="pz.id">
          <div class="d" v-if="pz.product.product_size !== null">
            <input type="radio" @click="productBySize(pz, pz.id)" name="size" value="1" />
            <label class=""> {{ pz.product.product_size.name }}</label>
          </div>

          <!-- <label class=""> pz</label> -->
        </li>
      </ul>
    </div>
    <h6 v-if="singleProct.hasOwnProperty('product_price')">Quantity : {{ singleProct.product_price[0].quantity }}
      available only</h6>
    <div class="quantity_btn">
      <div v-if="$store.getters.isExist(singleProct.id)" class="qounter">
        <button @click="deductQty(singleProct)">
          <i class="icon ion-md-remove"></i>
        </button>
        <input type="text" id="qty" :value="$store.getters.prodQty(singleProct.id)" readonly />
        <button :disabled="singleProct.product_price[0].quantity === $store.getters.prodQty(singleProct.id)"
          @click="addQty">
          <i class="icon ion-md-add"></i>
        </button>
      </div>
      <div v-if="Object.keys(singleProct).length !== 0" class="">
        <button v-if="!$store.getters.isExist(singleProct.id) && singleProct.product_price[0].quantity > 0"
          class="btnadd btn" @click="addToBag(singleProct)">
          Add to Bag
        </button>
      </div>
      <div v-if="Object.keys(singleProct).length !== 0" class="product_action">
        <button v-if="!$store.getters.isExist(singleProct.id) && singleProct.product_price[0].quantity <= 0"
          style="width:100%" class="plus product-add-btn" @click="notifyMe(singleProct)">
          Sold Out. Notify Me
        </button>
      </div>
      <div v-if="showModal">
        <transition name="modal">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">{{ notifyMeData.name }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true" @click="showModal = false">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="form-group" style="margin-bottom: 1rem">
                      <input type="text" class="form-control" v-model="name" placeholder="Your Name">
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="mobileNo" placeholder="Your Mobile Number">
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="showModal = false">
                      Close
                    </button>
                    <button type="button" class="btn btn-primary"
                      @click="notifyMeForSubmit(name, mobileNo, notifyMeData)">Notify Me
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      showPriceRange: true,
      productName: "",
      group_product_id: "",
      brandName: "",
      origin: "",
      slug: "",
      description: "",
      price: "",
      sku: "",
      deliveryCharge: "",
      colors: [],
      sizes: [],
      singleProct: {},
      selectedClrId: 0,
      isSelected: false,
      showModal: false,
      notifyMeData: "",
      mobileNo: "",
      name: "",
      img: "",
      meta_title: "",
      meta_desc: "",
      meta_keyword: "",
    };
  },
  watch: {
    productDetails() {
      this.productName = this.productDetails.name;
      this.meta_title = this.productDetails.meta_title;
      this.meta_desc = this.productDetails.meta_description;
      this.meta_keyword = this.productDetails.meta_keyword;
    },
  },
  computed: {
    ...mapGetters([
      "productDetails",
      // "productPrice",
      "productColor",
      "productSize",
      "shoppingBag",
    ]),
  },
  mounted() {
    this.productName = this.productDetails.name;
    this.sku = this.productDetails.sku;
    this.meta_title = this.productDetails.meta_title;
    this.meta_desc = this.productDetails.meta_description;
    this.meta_keyword = this.productDetails.meta_keyword;
    this.setProductInfo();

  },
  metaInfo() {
    return {
      title: this.meta_title,
      titleTemplate: "%s | Savers Hall",
      meta: [
        {
          name: "description",
          content: this.meta_desc,
        },
        {
          name: "keyword",
          content: this.meta_keyword,
        },
      ],
    };
  },
  methods: {
    setProductInfo() {
      this.productName = this.productDetails.name;
      this.brandName = this.productDetails.brand.name;
      this.origin =
        this.productDetails.groupProducts[0].product.country_of_manufacture_id.name;
      this.slug = this.productDetails.brand.slug;
      this.description = this.productDetails.short_description;
      this.deliveryCharge = this.productDetails.flat_rate_value;
      this.colors = this.productColor;
      this.sizes = this.productSize;
      this.sku = this.productDetails.sku;
      console.log(this.productDetails);
    },
    productByColor(product, sId) {
      console.log("===", product, sId);

      this.showPriceRange = false;
      this.productName = product.product.name;
      this.brandName = product.product.brand.name;
      this.origin = product.product.country_of_manufacture_id.name;
      this.slug = product.product.brand.slug;
      this.description = product.product.short_description;
      this.deliveryCharge = product.product.flat_rate_value;
      this.price = product.product.product_price[0].price;
      this.singleProct = product.product;
      this.sku = product.product.sku;
      this.group_product_id = product.group_product_id;
      this.isSelected = true;
      this.selectedClrId = sId;
      this.$store.dispatch("setZoomimage", product.product.product_images);

      this.meta_title = product.product.meta_title;
      this.meta_desc = product.product.meta_description;
      this.meta_keyword = product.product.meta_keyword;
      console.log("Product ===> ", product.product);
    },
    productBySize(product, sId) {
      console.log("=============pro zize", product);
      this.showPriceRange = false;
      this.productName = product.product.name;
      this.brandName = product.product.brand.name;
      this.origin = product.product.country_of_manufacture_id.name;
      this.slug = product.product.brand.slug;
      this.description = product.product.short_description;
      this.deliveryCharge = product.product.flat_rate_value;
      this.price = product.product.product_price[0].price;
      this.singleProct = product.product;
      this.sku = product.product.sku;
      this.group_product_id = product.group_product_id;
      this.isSelected = true;
      this.selectedClrId = sId;
      this.$store.dispatch("setZoomimage", product.product.product_images);
      console.log(product.product);
      console.log(product);
    },
    addToBag() {
      // console.log('dfgd', this.productDetails);
      if (this.isSelected === false) {
        alert("Please select color/size");
      } else {
        this.$store.dispatch("sohwCart");
        this.singleProct.qty = 1;
        this.singleProct.group_product_id = this.group_product_id;
        this.singleProct.cartImage =
          this.singleProct.product_images[0].normal_image;

        if (this.$store.getters.preCountGroupProduct(this.group_product_id) > 0) {
          console.log('00');
          let price = 0;
          let lastQty = this.$store.getters.preCountGroupProduct(this.group_product_id) + 1;

          if (
            lastQty >= this.singleProct.product_price[0].tier_quantity_three &&
            this.singleProct.product_price[0].tier_quantity_three !== 0
          ) {
            console.log('11');
            price = this.singleProct.product_price[0].tier_price_three;
            this.$store.getters.updateGroupProduct(this.group_product_id, price)

          } else if (
            lastQty >= this.singleProct.product_price[0].tier_quantity_two &&
            this.singleProct.product_price[0].tier_quantity_two !== 0 &&
            lastQty < this.singleProct.product_price[0].tier_quantity_three
          ) {
            console.log('22');
            price = this.singleProct.product_price[0].tier_price_two;
            this.$store.getters.updateGroupProduct(this.group_product_id, price)

          } else if (
            lastQty >= this.singleProct.product_price[0].tier_quantity_two &&
            this.singleProct.product_price[0].tier_quantity_two !== 0 &&
            this.singleProct.product_price[0].tier_price_three === 0
          ) {
            console.log('33');
            price = this.singleProct.product_price[0].tier_price_two;
            this.$store.getters.updateGroupProduct(this.group_product_id, price)
            // this.shoppingBag.forEach((item) => {
            //   if (item.group_product_id === this.group_product_id) {
            //     item.unitPrice= price;
            //     item.amount= item.qty * price;
            //   }
            // });
          } else if (
            lastQty >= this.singleProct.product_price[0].tier_quantity_one &&
            this.singleProct.product_price[0].tier_quantity_one !== 0 &&
            lastQty < this.singleProct.product_price[0].tier_quantity_two
          ) {
            console.log('44');
            price = this.singleProct.product_price[0].tier_price_one;
            this.$store.getters.updateGroupProduct(this.group_product_id, price)

          } else if (
            lastQty >= this.singleProct.product_price[0].tier_quantity_one &&
            this.singleProct.product_price[0].tier_quantity_one !== 0 &&
            this.singleProct.product_price[0].tier_price_two === 0
          ) {
            console.log('55');
            price = this.singleProct.product_price[0].tier_price_one;
            this.$store.getters.updateGroupProduct(this.group_product_id, price)

          } else if (this.singleProct.product_price[0].offer_price > 0) {
            console.log('66');
            price = this.singleProct.product_price[0].offer_price;
            this.$store.getters.updateGroupProduct(this.group_product_id, price)

          } else {
            console.log('77');
            price = this.singleProct.product_price[0].price;
            this.$store.getters.updateGroupProduct(this.group_product_id, price)

          }

          this.singleProct.unitPrice = price;

        } else {
          this.singleProct.unitPrice = this.singleProct.product_price[0].offer_price > 0
            ? this.singleProct.product_price[0].offer_price
            :
            this.singleProct.product_price[0].price;
        }


        this.$store.dispatch("addToBag", this.singleProct);
      }
      console.log("product from component", this.singleProct);
    },

    addQty() {
      // console.log('update', product.product_price[0].tier_quantity_three)
      this.singleProct.unitPrice = this.singleProct.product_price[0].offer_price > 0
        ?
        this.singleProct.product_price[0].offer_price
        :
        this.singleProct.product_price[0].price;
      this.$store.dispatch("addQty", this.singleProct);
    },
    deductQty(product) {
      if (this.$store.getters.prodQty(product.id) > 0) {
        this.$store.dispatch("deductQty", product);
      }
      if (this.$store.getters.prodQty(product.id) == 0) {
        this.$store.dispatch("removeFromCart", product);
      }
    },
    notifyMe(data) {
      this.notifyMeData = data;
      this.showModal = true;
    },
    notifyMeForSubmit(name, phone, product) {
      if (name !== "" && phone !== "" && product.id) {
        let notifyDataForPass = {
          name: name,
          phone: phone,
          product_id: product.id,
        };
        this.$store
          .dispatch("soldOutNotifyMe", notifyDataForPass)
          .then((res) => {
            console.log("Response Data = ", res);
            if (res === undefined) {
              window.alert("Successfully Submitted.");
              this.mobileNo = "";
              this.name = "";
            }
          });
        console.log("Data for pass: ", notifyDataForPass);
      }
      this.showModal = false;
    },
  },
};
</script>

<style>
.product_action .minus:hover,
.product_action .plus:hover {
  background: #353535;
}

.product_action .minus,
.product_action .plus {
  background: #434343;
  text-align: center;
  line-height: 40px;
  height: 40px;
  color: #fff;
  width: 50px;
  border: none;
  outline: none;
  font-size: 22px;
  min-width: 50px;
  box-shadow: none;
  font-weight: bold;
  padding: 0px 25px;
  border-radius: 5px;
  margin-top: 3px;
}

.vs__selected-options {
  width: 250px !important;
}

#vs1__listbox {
  width: 275px !important;
}

div#vs1__combobox {
  height: 37px;
}

button.vs__clear {
  display: none;
}

.product_details .choice_color input {
  text-align: left !important;
}

@media (min-width: 100px) and (max-width: 991.99px) {

  .pl-5,
  .px-5 {
    padding-left: 15px !important;
    padding-top: 15px !important;
  }

  .detail {
    display: none;
  }
}

@media (min-width: 992px) {
  .vs__selected-options {
    width: 400px !important;
  }
}
</style>
