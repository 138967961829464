<template>
  <section class="product_section top_selling">
    <div class="container">
      <div class="section_title">
        <h3>Related Products</h3>
      </div>
      <!-- {{topSelling}} -->
      <SingleProduct :products="productDetails.releted_products[0].products" />

      <!--<div class="text-center">
        <a class="load_more" href="">Load More</a>
      </div>-->
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import SingleProduct from "@/components/frontend/singleProduct/SingleProduct.vue";
export default {
  name: "ProductReleted",
  components: {
    SingleProduct
  },
  computed: {
    ...mapGetters(["ReletedProduct", "productDetails"]),
  },
};
</script>

<style></style>
