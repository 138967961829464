<template>
  <section class="product_details">
    <div class="container">
      <!-- {{ productDetails }} -->
      <div class="row">
        <div class="col-md-4">
          <div class="details_img">
            <Zoomer></Zoomer>
          </div>
        </div>
        <div class="col-md-8 pl-5">
          <!--<pre>{{productDetails}}</pre>-->
          <GroupProduct v-if="productDetails.product_type === 2" />
          <SingleProduct v-else />
        </div>
      </div>
    </div>
    <!-- <ProductArticle></ProductArticle> -->
    <!-- <ProductReleted></ProductReleted> -->
  </section>
</template>

<script>
import { mapGetters } from "vuex";
// import ProductArticle from "@/components/frontend/productDetails/ProductArticle.vue";
// import ProductReleted from "@/components/frontend/productDetails/ProductReleted.vue";
import Zoomer from "@/components/frontend/productDetails/Zoomer.vue";
import SingleProduct from "./SingleProduct.vue";
import GroupProduct from "./GroupProduct.vue";
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
window.fbq('init', '865834318321102');
window.fbq('track', 'PageView', {value: 0, currency: 'BDT'});
export default {
  name: "ProductDetailsSection",
  components: {
    // ProductArticle,
    // ProductReleted,
    Zoomer,
    SingleProduct,
    GroupProduct,
  },
  computed: {
    ...mapGetters([
      "productDetails",
      // "productPrice",
      // "productColor",
      // "productSize",
    ]),
  },
};
</script>

<style lang="scss">
@import "@/assets/frontend/style/details.scss";
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700&display=swap");
</style>
