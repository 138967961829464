<template>
    <div>
        <section class="content">
            <article class="demo-area">
                <img class="demo-trigger" :src="'https://savershall.com/suadmin/'+normalZoomImage"
                     :data-zoom="'https://savershall.com/suadmin/'+largeZoomImg"
                />
                <div class="detail ddd"></div>
            </article>
            <div class="thumbs-area">
                <carousel v-if="zoomeImage.length" class="tabs_product" id="gal1" :items=4 :autoplay="false"
                          :nav="true" :loop="false" :dots="false" :margin="15" :autoWidth="true">
                    <img v-for="(img, index) in zoomeImage" :key="index"
                         :src="'https://savershall.com/suadmin/'+img.thumb_image" @click="cahnageZoomImage(img)"
                         alt="">
                </carousel>
            </div>

        </section>

    </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import Drift from "drift-zoom";
  import carousel from 'vue-owl-carousel'

  export default {
    components: {
      carousel
    },
    data() {
      return {
        images: {},
        sliderImages: [],
        zoomerOptions: {
          zoomFactor: 3,
          pane: "container-rount",
          hoverDelay: 300,
          namespace: "zoomer-bottom",
          move_by_click: false,
          scroll_items: 4,
          choosed_thumb_border_color: "#dd2c00",
          scroller_position: "bottom",
          zoomer_pane_position: "right",
        },
        thumZoomImg: '',
        largeZoomImg: '',
        normalZoomImage: ''
      };
    },
    watch: {
      zoomeImage(v) {
        console.log('asdf', v);
        this.initDrift();
        this.slideImage(v);
      }
    },
    mounted() {
      // this.setzoomImage();
      this.images = {
        thumbs: this.productImage("thumb_image"),
        normal_size: this.productImage("normal_image"),
        large_size: this.productImage("large_image"),
      };
      this.initDrift()
      this.setzoomImage()
    },
    computed: {
      ...mapGetters(["productImage", "zoomeImage"]),
    },

    methods: {
      setzoomImage() {
        this.normalZoomImage = this.zoomeImage[0].normal_image
        this.largeZoomImg = this.zoomeImage[0].large_image
        this.thumZoomImg = this.zoomeImage[0].thumb_image

        console.log('set img', this.zoomeImage);
        // console.log(this.zoomeImage);
      },
      initDrift() {
        var demoTrigger = document.querySelector(".demo-trigger");
        var paneContainer = document.querySelector(".detail");
        new Drift(demoTrigger, {
          paneContainer: paneContainer,
          inlinePane: false,
        });
        setTimeout(() => {
          this.setzoomImage()
        }, 600);
      },
      cahnageZoomImage(img) {
        this.largeZoomImg = img.large_image
        this.thumZoomImg = img.thumb_image
        this.normalZoomImage = img.normal_image
      },
      slideImage(img) {
        console.log("slide ====>", img)
        this.sliderImages = [];
        this.sliderImages = [];
        setTimeout(() => {
          this.sliderImages = img;
        }, 500);

      }
    }
  };
</script>
<style>
    .thumb-list > img {
        height: 95px !important;
        width: 95px !important;
        overflow: hidden;
    }

    .thumb-list > img:first-child {
        height: 0px !important;
        width: 0px !important;
    }

    .thumb-list > img:last-child {
        height: 0px !important;
        width: 0px !important;
    }

    picture {
        text-align: center;
    }

    .demo-area {
        background: #fafafa;
        border-radius: 8px;
        padding: 20px;
        width: 100%;
        position: relative;
    }

    .demo-area section {
        padding-top: 0;
    }

    .demo-trigger {
        display: inline-block;
        width: 100%;
    }

    .detail {
        position: absolute;
        left: 110%;
        top: 0;
        width: 100%;
        height: 500px;
    }

    .details_img:hover .detail {
        z-index: 999 !important;
    }

    .product_details article {
        padding: 5px 0 15px;
        margin: 5px 0 5px !important;
        border: none !important;
    }

    .thumbs-area img {
        width: 100px !important;
        margin-right: 10px;
        margin-top: 10px;
    }

    @media (min-width: 100px) and (max-width: 991.99px) {
        article.demo-area:after {
            position: absolute;
            content: "";
            background: transparent;
            height: 100%;
            width: 100%;
            left: 0;
        }
        .pl-5, .px-5 {
            padding-left: 0rem !important;
        }

    }
    /* .product_details article {
      padding: 5px 0 15px;
      margin: 15px 0 25px;
    } */
    /*@media (min-width: 300px) and (max-width: 992px) {
        .demo-area img{
            padding: 60px;
        }
        .detail {
            position: absolute;
            left: 60%;
            top: 5px;
            width: 150px;
            height: 150px;
            border-radius: 100px !important;
        }

    }*/
    @media (min-width: 100px) and (max-width: 991.99px) {
        .thumbs-area img {
            width: unset !important;
            margin-right: 10px;
            margin-top: 1px;
            height: 50px;
        }
    }

</style>
