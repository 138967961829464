<template>
  <div class="product_content">
    <h1
                  style="
                    font-size: 18px;
                    font-weight: bolder;
                    color: #6f6f6f;
                    text-align: start;
                    margin-bottom: 5px;
                  "
                >
                  {{ productDetails.name }}
                </h1>
   
<!--      <pre>{{productDetails}}</pre>-->
      <!-- <b>{{ productDetails.name }}</b> -->
      <p>By <router-link :to="{ name: 'brand', params: { slug: productDetails.brand.slug } }">
          {{ productDetails.brand.name }}
        </router-link>
        <!--{{ productDetails.brand.name }}-->
      </p>
  
    <p v-if="productDetails.country_of_manufacture_id">
      Manufacturer Country: <strong>{{ productDetails.country_of_manufacture_id.name }}</strong>
    </p>
    <hr class="nomobile" v-if="productDetails.short_description">
    <article class="nomobile" v-if="productDetails.short_description">
      <div class="short-desc" v-html="productDetails.short_description"></div>
    </article>
    <hr class="nomobile" v-if="productDetails.short_description">
    <!--<pre>{{productDetails.product_price[0]}}</pre>-->
    <template v-if="productDetails.product_type !== 2">
      <h6 v-if="productDetails.product_price[0]">SKU - {{productDetails.sku}}</h6>

      <!--<h6 v-if="productDetails.product_price[0]">Price</h6>-->
      <h3 v-if="!proPrice"><span style="font-size: 17px; font-weight: 500">
        Price :</span>
        Was : <del>{{ productDetails.product_price[0].offer_price > 0?productDetails.product_price[0].price > 0?productDetails.product_price[0].price:'':'' }}৳</del>
        Now : {{ productDetails.product_price[0].offer_price?productDetails.product_price[0].offer_price: productDetails.product_price[0].price}}৳
        <span>
              (
              {{percentCalc(productDetails.product_price[0].offer_price > 0?productDetails.product_price[0].price > 0?productDetails.product_price[0].price:'':'' ,
            productDetails.product_price[0].offer_price?productDetails.product_price[0].offer_price: productDetails.product_price[0].price)}}%
              )
            </span>
      </h3>
      <h3 v-if="proPrice"><span style="font-size: 17px; font-weight: 500">Price :</span> {{proPrice}} TK</h3>
    </template>
    <template v-else>
      <!-- <h6>Start At</h6> -->
      <h3>{{ productPrice }} TK</h3>
    </template>
    <h4 v-if="productDetails.flat_rate_value > 0">
      Deliver Charge : {{ productDetails.flat_rate_value }} TK
    </h4>
    <h6 v-if="productDetails.product_type === 2">Colors</h6>
    <ul v-if="productDetails.product_type === 2" class="choice_color">
      <li v-for="pc in productColor" :key="pc.id">
        <!-- {{pc.color_image}} -->
        <!-- <input
                  class="color_one"
                  type="radio"
                  name="colors"
                  :value="pc.name"
                /> -->
        <router-link to="">
          <img class="img-rounded" :src="'https://savershall.com/suadmin/'+pc.color_image" alt="" />
        </router-link>
        <!-- <label>&nbsp; {{ pc.product_id }} - {{ pc.name }}</label> -->
      </li>
    </ul>

    <h6 v-if="productDetails.product_type === 2">Size</h6>
    <ul v-if="productDetails.product_type === 2" class="size_box">
      <li v-for="pz in productSize" :key="pz.id">
        <!-- {{pz}} -->
        <input type="radio" name="size" :value="pz.name" />
        <label class=""> {{ pz.name }}</label>
      </li>
    </ul>
    <h6>Quantity : <span v-if="productDetails.product_price[0].quantity>0">{{productDetails.product_price[0].quantity}}</span> <span v-else>0</span> available only</h6>
    <div class="quantity_btn">
      <div v-if="$store.getters.isExist(productDetails.id)" class="qounter">
        <button @click="deductQty(productDetails)">
          <i class="icon ion-md-remove"></i>
        </button>
        <input type="text" id="qty" :value="$store.getters.prodQty(productDetails.id)" readonly />
        <button :disabled="productDetails.product_price[0].quantity === $store.getters.prodQty(productDetails.id)" @click="addQty(productDetails)"><i class="icon ion-md-add"></i>
        </button>
      </div>
      <div v-if="!$store.getters.isExist(productDetails.id) && productDetails.product_price[0].quantity>0" class="product_action">

        <p v-if="accessToken"><strong v-if="productDetails.buy_with_point === 1" style="color: #ff6c25">You can buy these products with your collected points.</strong></p>
        <button class="plus product-add-btn btnadd btn" @click="addtoBag(productDetails)">
          Add to Basket
        </button>
      </div>
      <div v-if="!$store.getters.isExist(productDetails.id) && productDetails.product_price[0].quantity<=0" class="product_action">
        <button style="width:100%" class="plus product-add-btn btnadd btn" @click="notifyMe(productDetails)">
          Sold Out. Notify Me
        </button>
      </div>
      <!--<button v-if="!$store.getters.isExist(productDetails.id)" class="btnadd btn"
        @click="addtoBag"
      >Add to Basket</button>-->
    </div>
    <h5>Add to wish list</h5>
    <div class="social_share">
      <p>Share</p>
      <a href=""><i class="icon ion-logo-facebook"></i></a>
      <a href=""><i class="icon ion-logo-twitter"></i></a>
      <a href=""><i class="icon ion-logo-googleplus"></i></a>
      <a href=""><i class="icon ion-logo-pinterest"></i></a>
    </div>
    <div>
      <!--<NotifyModal v-model="showModal" :product="notifyMeData" :modalView="showModal"></NotifyModal>-->
      <div v-if="showModal">
        <transition name="modal">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">{{notifyMeData.name}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true" @click="showModal = false">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="form-group" style="margin-bottom: 1rem">
                      <input type="text" class="form-control" v-model="name" placeholder="Your Name">
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="mobileNo" placeholder="Your Mobile Number">
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="showModal = false">Close</button>
                    <button type="button" class="btn btn-primary" @click="notifyMeForSubmit(name, mobileNo, notifyMeData)">Notify Me</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showModal: false,
      notifyMeData: "",
      mobileNo: "",
      name: "",
      name2: "ghghghgh",
      proPrice: 0,
    };
  },
  metaInfo() {
    return {
      title: this.productDetails.meta_title,
      titleTemplate: "%s | Savers Hall",
      meta: [
        {
          name: "description",
          content: this.productDetails.meta_description,
        },
        {
          name: "keyword",
          content: this.productDetails.meta_keyword,
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
    $route(newVlue, oldValue) {
      if (newVlue) {
        console.log("New====>>>>>", newVlue);
      }
      if (oldValue) {
        console.log("Old====>>>>>", oldValue);
      }
      window.scrollTo(0, 0);
    },
  },
  computed: {
    ...mapGetters([
      "productDetails",
      "productPrice",
      "productColor",
      "productSize",
      "shoppingBag",
      "accessToken"
    ]),
  },
  methods: {
    addtoBag() {
      // console.log('productDetails',this.productDetails);
      //this.$store.dispatch('sohwCart')
      this.productDetails.qty = 1;
      this.productDetails.cartImage =
        this.productDetails.product_images[0].normal_image;
      this.productDetails.product_price[0].offer_price > 0
        ? (this.productDetails.unitPrice =
            this.productDetails.product_price[0].offer_price)
        : (this.productDetails.unitPrice =
            this.productDetails.product_price[0].price);

      this.$store.dispatch("addToBag", this.productDetails);
      console.log(this.productDetails);
    },
    addQty(product) {
      // console.log('update', product.product_price[0].tier_quantity_three)
      this.$store.dispatch("addQty", product);
      console.log("add", product);
      /*if (product.qty <= product.product_price[0].quantity) {
        if (
          product.qty >= product.product_price[0].tier_quantity_three &&
          product.product_price[0].tier_quantity_three !== 0
        ) {
          this.proPrice = product.product_price[0].tier_price_three;
        } else if (
          product.qty >= product.product_price[0].tier_quantity_two &&
          product.qty < product.product_price[0].tier_quantity_three
        ) {
          this.proPrice = product.product_price[0].tier_price_two;
        } else if (
          product.qty >= product.product_price[0].tier_quantity_one &&
          product.qty < product.product_price[0].tier_price_two
        ) {
          this.proPrice = product.product_price[0].tier_price_one;
        } else if (product.product_price[0].offer_price) {
          this.proPrice = product.product_price[0].offer_price;
        } else {
          this.proPrice = product.product_price[0].price;
        }
      }*/
    },
    deductQty(product) {
      console.log("deduct", product);
      if (this.$store.getters.prodQty(product.id) > 0) {
        this.$store.dispatch("deductQty", product);
      }
      if (this.$store.getters.prodQty(product.id) == 0) {
        this.$store.dispatch("removeFromCart", product);
      }
      /*if (product.qty <= product.product_price[0].quantity) {
        if (
          product.qty >= product.product_price[0].tier_quantity_three &&
          product.product_price[0].tier_quantity_three !== 0
        ) {
          this.proPrice = product.product_price[0].tier_price_three;
        } else if (
          product.qty >= product.product_price[0].tier_quantity_two &&
          product.qty < product.product_price[0].tier_quantity_three
        ) {
          this.proPrice = product.product_price[0].tier_price_two;
        } else if (
          product.qty >= product.product_price[0].tier_quantity_one &&
          product.qty < product.product_price[0].tier_price_two
        ) {
          this.proPrice = product.product_price[0].tier_price_one;
        } else if (product.product_price[0].offer_price) {
          this.proPrice = product.product_price[0].offer_price;
        } else {
          this.proPrice = product.product_price[0].price;
        }
      }*/
    },
    notifyMe(data) {
      this.notifyMeData = data;
      this.showModal = true;
    },
    notifyMeForSubmit(name, phone, product) {
      if (name !== "" && phone !== "" && product.id) {
        let notifyDataForPass = {
          name: name,
          phone: phone,
          product_id: product.id,
        };
        this.$store
          .dispatch("soldOutNotifyMe", notifyDataForPass)
          .then((res) => {
            console.log("Response Data = ", res);
            if (res === undefined) {
              window.alert("Successfully Submitted.");
              this.mobileNo = "";
              this.name = "";
            }
          });
      }
      this.showModal = false;
    },
    percentCalc(wasVal, newVal){
      if(newVal && wasVal){
        var per = (newVal*100)/wasVal;
        return Math.round(100-per);
      }
    }
  },
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.short-desc p {
  margin-bottom: 0px !important;
}
.owl-carousel .owl-nav {
  top: calc(50% - 5px) !important;
}
@media (min-width: 100px) and (max-width: 991.99px) {
  .pl-5,
  .px-5 {
    padding-left: 15px !important;
    padding-top: 15px !important;
  }
  .detail {
    display: none;
  }
  .short-desc{
    display: none;
  }
  .nomobile{
    display: none;
  }
}
</style>
